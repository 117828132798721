import datapulseholding from "../images/datapulse-temp-holding.png"
import homepage_icon from "../images/icons/homepage_icon.svg"

const homePageIcon = (): JSX.Element => {
    return (
        < a href="/" title="Take me home!">
            <img className="Icon" src={homepage_icon}
                alt="LinkedIn Icon" />
        </a >
    );
};

const AbiramiPhotoStudio = (): JSX.Element => {
    return (
        <div className="full-page">
            <meta http-equiv="refresh" content="0; URL=mailto:abiramiphoto@gmail.com?subject=Files%20for%20Printing&body=Hi,%0A%0APlease%20attach%20the%20files%20for%20printing.%0A%0AName:%20%0A%0AThanks for printing with Abirami Photo Studio!"/>
            <div className="full-page-container" >
                <div className="contacts-container">
                        <div className="full-page-container">
                            <div className='contact-image-container'>
                                <a href="/">
                                    <img className="Photo-holder-contact" src={datapulseholding}
                                        alt="DataPule Logo" />
                                </a>
                            </div>
                        </div>
                        <div className="center-container">
                            <div>
                                <div className="Name-heading" style={{ color: "#0d4475"}}>
                                    <a href="/" className="text-clean" color="#0d4475">DataPulse</a>
                                </div>
                                <div className="" style={{ color: "#3f4f61" }}>
                                    <p>If you would like a similar QR Code or NFC for your company, reach out to DataPulse now!</p><br/><br/>
                                </div>
                                <div>
                                    <a href="mailto:enquiry@mydatapulse.co.uk?subject=Let%27s%20chat...I%20have%20a%20question!" className="text-clean">✉️ enquiry@mydatapulse.co.uk</a><br/>
                                    <a href="tel:+447456006128" className="text-clean">📞 +44 (0) 7456 006128</a><br/>
                                    <a href="https://drive.google.com/uc?export=download&id=1scvOyje9pYQT4cST86zgUZ9oRzYcjG4V" className="text-clean">🪪 Save Contact Card</a><br/>
                                    <a href="/" className="text-clean">🔗 www.mydatapulse.co.uk</a>
                                    <br/>
                                    <br/>
                                    {/* {homePageIcon()} */}
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
};

export default AbiramiPhotoStudio;


// https://drive.google.com/uc?export=download&id=15s11gjFw26v121f2sUZwuLpAjw-OUJ6V

