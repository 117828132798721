import datapulseholding from "../images/datapulse-temp-holding.png"

import linkedin_icon from "../images/icons/linkedin_icon.svg"
import email_icon from "../images/icons/new_email_icon.svg"
import contact_form_icon from "../images/icons/contact_form_icon.svg"
import slides_icon from "../images/icons/slides_3.svg"

const ContactFormIcon = (): JSX.Element => {
    return (
        // href only needed for button functionality / hover for message
        <a href = "Get in touch" title="Get in touch!">
            <img className="Icon" src={contact_form_icon} data-tally-open="nrVKep" data-tally-width="450" data-tally-emoji-text="👋"
                data-tally-emoji-animation="wave" data-tally-auto-close="6500" />
        </a>
    );
};

const LinkedInIcon = (): JSX.Element => {
    return (
        < a href="https://www.linkedin.com/company/myDataPulse" title="Find us on LinkedIn!" target = "_blank">
            <img className="Icon" src={linkedin_icon}
                alt="LinkedIn Icon" />
        </a >
    );
};

const SlidesIcon = (): JSX.Element => {
    return (
        < a href="https://docs.google.com/presentation/d/12vq_uX-gnbAuEIPfn2ofpcYoMaEP19yx/present" title="Check out our slides!" target = "_blank">
            <img className="Icon" src={slides_icon}
                alt="Slides Icon" />
        </a >
    );
};

const EmailIcon = (): JSX.Element => {
    return (
        < a href="mailto:enquiry@mydatapulse.co.uk?subject=Let's%20chat...I%20have%20a%20question!" title="Send us an Email!" >
            <img className="Icon" src={email_icon}
                alt="Email Icon" />
        </a >
    );
};


const Home = (): JSX.Element => {
    return (
        <div className="App">
            {/* Home Section */}
            <div className="Home section" id="home"
                style={{
                    backgroundColor: "#F9F9F9"
                }}>

                <div className="Header">

                </div>

                <div className="body">
                    <div>
                        <img className="Photo-holder" src={datapulseholding}
                            alt="DataPule Logo" />

                    </div>
                    <br/>
                    <div className="Name-heading" style={{ color: "#0d4475" }}>
                        DataPulse
                    </div>

                    <div className="Subheading" style={{ color: "#3f4f61" }}>
                        <p>Innovative partners to Automate, Analyse & Accelerate your business</p>
                    </div>
                </div>

                <div className="Footer">
                    <div className="Icons-container">
                        {ContactFormIcon()}
                        {SlidesIcon()}
                        {LinkedInIcon()}
                        {EmailIcon()}
                    </div>
                </div>

            </div>
        </div>

    );
};

export default Home;
