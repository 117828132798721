import React from 'react';
import { Routes, Route } from "react-router-dom";

import './website.css';

import Home from './pages/home';
import Contact from './pages/contact';
import AbiramiPhotoStudio from './pages/abirami_photo';
import NotFound from './pages/notFound';

const App = (): React.ReactElement => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='*' element={<NotFound />} />
      <Route path='contact' element={<Contact />} />
      <Route path='abirami-photo-studio' element={<AbiramiPhotoStudio />} />
    </Routes >
  );
};

export default App;
